import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import {
  AccessButton,
  Container,
  ExternalLinkWhite,
  HalfWidthSmallSubtitle,
  SectionBox,
  BodyTextLight,
  SectionHeader,
} from '../base'

export const fluidImage11 = graphql`
  fragment fluidImage11 on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export function ApplyAsCharity(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      CharitiesHandSideways: file(
        relativePath: { eq: "images/CharitiesHandSideways.png" }
      ) {
        ...fluidImage11
      }
      UsingChoiceHandLarge: file(
        relativePath: { eq: "images/UsingChoiceHandLarge.png" }
      ) {
        ...fluidImage11
      }
    }
  `)
  return (
    <SectionBox bg="primary.text">
      <Container width={[1, 1, 1 / 2]} pl={[3, 4, 0]} pt={[0, 0, 4]}>
        <Box width={[0, 0, 1 / 7]} alignSelf="start" pb={[0, 2, 3]}>
          <Img fluid={data.CharitiesHandSideways.childImageSharp.fluid} />
        </Box>
        <Box width={[1 / 8, 1 / 12, 0]} alignSelf="start" pb={[0, 2, 3]}>
          <Img fluid={data.UsingChoiceHandLarge.childImageSharp.fluid} />
        </Box>
        <SectionHeader color="primary.main">Apply as a charity</SectionHeader>
      </Container>
      <Container
        width={[1, 1, 2 / 7]}
        mt={[4, 4, '-18rem']}
        px={[3, 4, 0]}
        ml={[0, 0, '32rem']}
        pb={4}
      >
        <Box alignSelf="center" pb={[4, 4, 4]}>
          <HalfWidthSmallSubtitle fontSize={[2, 4, 4]} fontWeight="black">
            Sign-up your charity so more Kiwis can support the good work you do.
          </HalfWidthSmallSubtitle>
          <BodyTextLight>
            When people use Choice to pay at our participating stores,
            they&#39;ll get to choose which charity they direct 50% of their
            Choice transaction fee to.
          </BodyTextLight>
        </Box>
        <AccessButton
          width={[2 / 3, 1 / 2, 1]}
          sx={{
            textDecoration: 'none',
          }}
        >
          <ExternalLinkWhite
            href="https://choicetopay.typeform.com/to/zcqw5o"
            sx={{ textDecoration: 'none' }}
          >
            <Text fontSize={[1, 2, 2]} fontWeight="semibold" textAlign="center">
              Apply as a charity
            </Text>
          </ExternalLinkWhite>
        </AccessButton>
      </Container>
    </SectionBox>
  )
}
