import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Flex } from 'rebass/styled-components'
import {
  Container,
  SectionBox,
  SmallSubtitle,
  Subtitle,
  SectionHeader,
} from '../base'

export const fluidImage9 = graphql`
  fragment fluidImage9 on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export function HundredsOfMillions(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      UsingChoiceHeart: file(
        relativePath: { eq: "images/UsingChoiceHeart.png" }
      ) {
        ...fluidImage9
      }
      UsingChoiceHandLarge: file(
        relativePath: { eq: "images/UsingChoiceHandLarge.png" }
      ) {
        ...fluidImage9
      }
      CharitiesNZ: file(relativePath: { eq: "images/CharitiesNZ.png" }) {
        ...fluidImage9
      }
    }
  `)
  return (
    <SectionBox bg="primary.text">
      <Box pt={[2, 0, 0]}>
        <Container width={['45%', 1 / 2, 2 / 9]}>
          <Box width={1} mb={4} py={[0, 4, 5]} ml={['4rem', '10rem', 0]}>
            <Img fluid={data.CharitiesNZ.childImageSharp.fluid} />
          </Box>
        </Container>

        <Container width={1} pl={[1, 3, '20rem']} mt={[0, -5, -7]}>
          <SectionHeader color="darkMuted" pb={[0, 0, 0]}>
            Kiwis are paying
          </SectionHeader>
          <SectionHeader pb={[0, 0, 0]} color="primary.main" fontWeight="black">
            hundreds of millions
          </SectionHeader>
          <SectionHeader pb={[0, 0, 0]} color="darkMuted">
            of dollars in transaction fees.
          </SectionHeader>
        </Container>
      </Box>
      <SmallSubtitle
        color="primary.main"
        textAlign="center"
        fontSize={[3, 4, 2]}
        pt={[4, 4, 4]}
      >
        That’s not the Choice way, but this is ...
      </SmallSubtitle>
      <Flex justifyContent="center" pt="4">
        <Box width={[1 / 8, 1 / 16, 1 / 56]} alignSelf="center">
          <Img fluid={data.UsingChoiceHandLarge.childImageSharp.fluid} />
        </Box>
      </Flex>
    </SectionBox>
  )
}
