import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Text } from 'rebass/styled-components'
import {
  AccessButtonReverse,
  Container,
  ExternalLink,
  HalfWidthSmallSubtitle,
  SectionBox,
  HeaderTitle,
  BodyTextLight,
} from '../base'

export const fluidImage1 = graphql`
  fragment fluidImage8 on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export function WholeReason(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      UsingChoiceHandLarge: file(
        relativePath: { eq: "images/UsingChoiceHandLarge.png" }
      ) {
        ...fluidImage8
      }
      CharitiesWaveMoving: file(
        relativePath: { eq: "images/CharitiesWaveMoving.png" }
      ) {
        ...fluidImage8
      }
      UsingChoicePhone1: file(
        relativePath: { eq: "images/UsingChoicePhone1.png" }
      ) {
        ...fluidImage8
      }
    }
  `)
  return (
    <SectionBox bg="primary.main">
      <Container width={[1, 1, 3 / 7]}>
        <Box width={[1 / 6, 1 / 10, 1 / 11]} pt={[2, 4, 0]} pb={[2, 2, 0]}>
          <Img fluid={data.CharitiesWaveMoving.childImageSharp.fluid} />
        </Box>
        <Box width={1}>
          <HeaderTitle color="secondary.main">Kia Ora!</HeaderTitle>
          <HeaderTitle color="primary.text">
            You&#39;re the whole reason we started this company
          </HeaderTitle>
        </Box>
      </Container>

      <Container
        width={[1, 1, 1 / 3]}
        pb={[4, 4, 2]}
        mt={['-14rem', 4, '-20rem']}
        ml={[0, 0, '34rem']}
      >
        <Box>
          <HalfWidthSmallSubtitle color="primary.text" pt={[7, 0, 0]}>
            We know how hard you&#39;ve been working to put our planet and
            people before profit. We&#39;re here to support you.
          </HalfWidthSmallSubtitle>
          <BodyTextLight color="primary.text">
            When your charity partners with Choice, Kiwis will be able to
            redirect half of their transaction fee to you every time they pay
            for something. Apply today and we will start the charity selection
            process.
          </BodyTextLight>
        </Box>
        <Box
          width={[1 / 7, 1 / 12, 1 / 9]}
          style={{ alignSelf: 'center' }}
          py={[4, 4, '3rem']}
        >
          <Img fluid={data.UsingChoiceHandLarge.childImageSharp.fluid} />
        </Box>
        <AccessButtonReverse
          width={[3 / 4, 1 / 2, 1]}
          mb={[1, 4, 2]}
          fontSize={[2, 2, 4]}
          fontWeight="semibold"
        >
          <ExternalLink href="https://choicetopay.typeform.com/to/zcqw5o">
            <Text sx={{ color: 'primary.main' }}>Apply as a charity</Text>
          </ExternalLink>
        </AccessButtonReverse>
      </Container>
    </SectionBox>
  )
}
