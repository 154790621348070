import React from 'react'
import { ApplyAsCharity } from '../components/charity/ApplyAsCharity'
import { Costs$0 } from '../components/charity/Costs$0'
import { HundredsOfMillions } from '../components/charity/HundredsOfMillions'
import { withLayout } from '../components/Layout'
import { Container } from '../components/base'
import { Redirect50 } from '../components/charity/Redirect50'
import { WholeReason } from '../components/charity/WholeReason'

const CharityPage = () => {
  return (
    <Container>
      <WholeReason />
      <HundredsOfMillions />
      <Redirect50 />
      <Costs$0 />
      <ApplyAsCharity />
    </Container>
  )
}

export default withLayout(CharityPage)
