import React from 'react'
import { Box } from 'rebass/styled-components'
import { SectionBox, Subtitle, TextImage } from '../base'

export function Redirect50(): React.ReactElement {
  return (
    <SectionBox bg="altSectionBackground">
      <Subtitle
        color="dark"
        fontSize={[6, 10, 13]}
        fontWeight="black"
        pr={[0, 0, 7]}
        py={[5, 6, 6]}
        px={[3, 3, 0]}
      >
        Choice will redirect <TextImage color="primary.main">50%</TextImage> of
        transactions fees to New Zealand charities!
      </Subtitle>
    </SectionBox>
  )
}
