import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Text } from 'rebass/styled-components'
import {
  Container,
  SectionBox,
  SeparatorLine,
  SmallSubtitle,
  Subtitle,
} from '../base'

export const fluidImage10 = graphql`
  fragment fluidImage10 on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export function Costs$0(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      CharitiesLowerFees: file(
        relativePath: { eq: "images/CharitiesLowerFees.png" }
      ) {
        ...fluidImage10
      }
    }
  `)
  return (
    <SectionBox bg="primary.text">
      <Box>
        <Container width={[1, 1, 3 / 7]} pt={[2, 4, 6]} px={[2, 3, 0]}>
          <Subtitle
            color="primary.main"
            fontWeight="black"
            fontSize={[8, 10, 12]}
          >
            It costs $0 for your charity
          </Subtitle>
        </Container>

        <Container
          width={[1, 1, 2 / 5]}
          mt={[3, 4, '-14rem']}
          ml={[0, 4, '35rem']}
        >
          <Box
            width={[1 / 6, 1 / 5, 1 / 7]}
            style={{ alignSelf: 'center' }}
            mr={[3, 5, 5]}
          >
            <Img fluid={data.CharitiesLowerFees.childImageSharp.fluid} />
          </Box>
          <SmallSubtitle
            color="dark"
            py={[3, 4, 4]}
            px={[2, 4, 5]}
            ml={[0, 0, -3]}
            sx={{ display: 'inline-block' }}
          >
            Choice is lowering the transaction fee cost for Kiwi merchants and
            <Text color="primary.main" sx={{ display: 'inline-block' }}>
              redirecting half the fee to charities like yours.
            </Text>
          </SmallSubtitle>
        </Container>
      </Box>
      <SeparatorLine color="secondary.main" />
    </SectionBox>
  )
}
